import { useState } from "react";

const NeighborhoodItem = ({ eachLocation }) => {
  const { imgUrl, location } = eachLocation;
  const [onLoad, setOnLoad] = useState(false);

  return (
    <div className="neighborhood-block">
      <a href=" ">
        <figure className={onLoad ? "" : "skeleton-loading"}>
          <img onLoad={() => setOnLoad(true)} src={imgUrl} alt={location} />
        </figure>
      </a>
      <h5>{location}</h5>
    </div>
  );
};

export default NeighborhoodItem;

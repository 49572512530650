import { useEffect, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";

const BlogItem = ({ eachBlog }) => {
  const { id, imagUrl, date, title } = eachBlog;
  const [onLoad, setOnLoad] = useState(false);

  useEffect(() => {
    customScript();
  }, []);

  const customScript = () => {
    $(".blog-main .blog-block p").matchHeight({ property: "height" });
  };

  const webTitle = title.replace(/ /g, "-");

  return (
    <div className="blog-block">
      <Link to={`/blog/${webTitle}/${id}`}>
        <figure className={onLoad ? "" : "skeleton-loading"}>
          <img onLoad={() => setOnLoad(true)} src={imagUrl} alt="" />
        </figure>
      </Link>
      <h5>{date}</h5>
      <p>{title}</p>
      <Link to={`/blog/${webTitle}/${id}`} className="btn btn-solid">
        Read More
      </Link>
    </div>
  );
};

export default BlogItem;

import { useEffect, useState } from "react";
// import initialPropertiesData from "../../properties-list.json";
import FeatureItem from "../../Components/FeatureItem";
import Pagination from "rc-pagination/lib/Pagination";
import { Autocomplete } from "@react-google-maps/api";
import { fromAddress } from "react-geocode";
import _ from "lodash";
import { GOOGLE_MAPS_KEY, PROPERTIES_URL } from "../../Global/API's";

const initialData = [
  {
    image: null,
    address: null,
    price: null,
    _id: null,
    bedrooms: null,
    totalBaths: null,
    sqft: null,
    remarksConcat: "",
    priceData: {},
  },
];

const FeaturedListing = () => {
  const [paginationObj, setPaginationObj] = useState({
    current: 1,
    pageSize: 10,
  });
  const [initialPropertiesData, setInitialPropertiesData] = useState([]);
  const [propertiesData, setPropertiesData] = useState(initialData);
  const [sortedValue, setSortedValue] = useState("0");
  const [searchResult, setSearchResult] = useState("Result: none");
  const [filterData, setFilterData] = useState({
    searchValue: { lat: "", lng: "", boundaryVal: [] },
    propertyType: "Any",
    sqftValue: "",
    minPrice: "",
    maxPrice: "",
    totalBeds: "",
    totalBaths: "",
    country: "",
    state: "",
    city: "",
    postalCode: "",
  });

  const getPropertiesData = async (limit = 10, skip = 0) => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const url = `${PROPERTIES_URL}?limit=${limit}&skip=${skip}`;
    const response = await fetch(url, options);
    if (response.ok) {
      const data = await response.json();
      setInitialPropertiesData(data);
      setPropertiesData(data);
    }
  };

  useEffect(() => {
    getPropertiesData();
  }, []);

  const onUpdateData = (data) => {
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      setPropertiesData(data);
    }, 1500);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const formattedAddress = place.formatted_address;
      handleGeocode(formattedAddress);
    }
  }

  const handleGeocode = (address) => {
    fromAddress(address, GOOGLE_MAPS_KEY)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const fetchCityBounds = async () => {
          await getCityBounds(lat, lng, response.results[0].place_id);
        };
        fetchCityBounds();

        setFilterData((prevState) => ({
          ...prevState,
          searchValue: { ...prevState.searchValue, lat, lng },
        }));
      })
      .catch(() => {
        alert("Please enter a valid location");
      });
  };

  const onReserFilter = () => {
    setFilterData({
      searchValue: { lat: "", lng: "", boundaryVal: [] },
      propertyType: "Any",
      sqftValue: "",
      minPrice: "",
      maxPrice: "",
      totalBeds: "",
      totalBaths: "",
      country: "",
      state: "",
      city: "",
      postalCode: "",
    });
    const ele = document.getElementById("addressSearch");
    ele.value = "";
    onSortProperties(sortedValue, initialPropertiesData.listings);
  };

  const getCityBounds = async (latitude, longitude, placeId) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_KEY}`
      );

      const data = await response.json();
      const filteredData = data.results.filter(
        (eachData) => eachData.place_id === placeId
      );
      if (data.status === "OK") {
        const bounds = filteredData[0].geometry.viewport;
        const ne = bounds.northeast;
        const sw = bounds.southwest;
        const nw = { lat: ne.lat, lng: sw.lng };
        const se = { lat: sw.lat, lng: ne.lng };

        const boundary = [
          { lat: sw.lat, lng: sw.lng },
          { lat: nw.lat, lng: nw.lng },
          { lat: ne.lat, lng: ne.lng },
          { lat: se.lat, lng: se.lng },
        ];
        setFilterData((prevState) => ({
          ...prevState,
          searchValue: { ...prevState.searchValue, boundaryVal: boundary },
        }));
      } else {
        console.error("Geocoding error:", filteredData.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
      return null;
    }
  };

  const onFilterSubmittion = () => {
    const boundary = new window.google.maps.Polygon({
      paths: filterData.searchValue.boundaryVal,
    });
    const filterPropertiesData = initialPropertiesData.listings.filter(
      (eachProperty) => {
        const isWithinBoundary =
          window.google.maps.geometry.poly.containsLocation(
            new window.google.maps.LatLng(
              Number(eachProperty.latitude),
              Number(eachProperty.longitude)
            ),
            boundary
          );
        return (
          (filterData.searchValue.lat === "" ? true : isWithinBoundary) &&
          (filterData.propertyType === "Any"
            ? true
            : eachProperty.propertyType.includes(filterData.propertyType)) &&
          (filterData.minPrice === ""
            ? true
            : Number(eachProperty.price) >= Number(filterData.minPrice)) &&
          (filterData.maxPrice === ""
            ? true
            : Number(eachProperty.price) <= Number(filterData.maxPrice)) &&
          Number(eachProperty.sqft) >= Number(filterData.sqftValue) &&
          Number(eachProperty.bedrooms) >= Number(filterData.totalBeds) &&
          Number(eachProperty.bathrooms) >= Number(filterData.totalBaths)
        );
      }
    );
    onSortProperties(sortedValue, filterPropertiesData);
  };

  const onSortProperties = (value, filterPropertiesData) => {
    setSortedValue(value);
    let filteredData;
    if (value === "0") {
      filteredData = _.sortBy(filterPropertiesData, "id");
    } else if (value === "1") {
      filteredData = _.sortBy(filterPropertiesData, "price").reverse();
    } else if (value === "2") {
      filteredData = _.sortBy(filterPropertiesData, "price");
    } else if (value === "3") {
      filteredData = _.sortBy(filterPropertiesData, "builtYear").reverse();
    } else if (value === "4") {
      filteredData = _.sortBy(filterPropertiesData, "bedrooms").reverse();
    } else if (value === "5") {
      filteredData = _.sortBy(filterPropertiesData, "bathrooms").reverse();
    } else if (value === "6") {
      filteredData = _.sortBy(filterPropertiesData, "sqft").reverse();
    } else if (value === "7") {
      filteredData = _.sortBy(filterPropertiesData, "lotSize").reverse();
    }
    setPropertiesData(initialData);
    onUpdateData(filteredData);
  };

  return (
    <section className="feature-list-main">
      <div className="container">
        <h2>Feature Listings</h2>
        <p>
          The following properties are listed with Selzer Realty. If you would
          like to review listings with an agent, click here so we can match you
          with the Realtor who can best meet your needs, or call us at the
          number listed above.
        </p>
      </div>
      <div className="container-fluid">
        <div className="feature-list">
          <div className="feature-list-right">
            <h4>Search Homes For Sale</h4>
            <div className="search-home">
              <div className="form-main">
                <form className="search-block">
                  <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                    <input
                      id="addressSearch"
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setFilterData((prevState) => ({
                            ...prevState,
                            searchValue: { lat: "", lng: "", boundaryVal: [] },
                          }));
                        }
                      }}
                      type="search"
                      placeholder="Location, address or mls #"
                      className="form-control"
                    />
                  </Autocomplete>
                  <i className="fa fa-search" aria-hidden="true"></i>
                </form>
              </div>
              <div className="row select-list">
                <div className="col-sm-6 form-group">
                  <div className="select-block">
                    <select
                      name="propertyType"
                      onChange={handleChange}
                      value={filterData.propertyType}
                      className="form-control"
                    >
                      <option value="" disabled>
                        Property Type
                      </option>
                      <option value="Any">Any</option>
                      <option value="Single Family Home">
                        Single Family Home
                      </option>
                      <option value="Multi Family">Multi-Family</option>
                      <option value="Condo">Condo</option>
                      <option value="Townhouse">Townhouse</option>
                      <option value="Mobile Home">Mobile Home</option>
                      <option value="Manufactured Home">
                        Manufactured Home
                      </option>
                      <option value="Vacant Land">Vacant Land</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Other Rentals">Other Rentals</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    name="sqftValue"
                    onChange={handleChange}
                    value={filterData.sqftValue}
                    placeholder="Square Footage"
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    name="minPrice"
                    onChange={handleChange}
                    value={filterData.minPrice}
                    placeholder="Min Price"
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <input
                    name="maxPrice"
                    onChange={handleChange}
                    value={filterData.maxPrice}
                    placeholder="Max Price"
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <div className="select-block">
                    <select
                      name="totalBeds"
                      onChange={handleChange}
                      value={filterData.totalBeds}
                      className="form-control"
                    >
                      <option value="" disabled>
                        Bed
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 form-group">
                  <div className="select-block">
                    <select
                      name="totalBaths"
                      onChange={handleChange}
                      value={filterData.totalBaths}
                      className="form-control"
                    >
                      <option value="" disabled>
                        Bath
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row select-list">
                <div className="col-sm-6 form-group">
                  <button
                    onClick={onFilterSubmittion}
                    type="button"
                    className="btn"
                  >
                    Submit
                  </button>
                </div>
                <div className="col-sm-6 form-group">
                  <button
                    onClick={onReserFilter}
                    type="button"
                    className="btn btn-bordered"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-list-left">
            <div className="feature-top-block">
              <div className="form-group">
                <label>Sort by : </label>
                <div className="select-block">
                  <select
                    onChange={(e) => {
                      onSortProperties(e.target.value, propertiesData);
                    }}
                    value={sortedValue}
                    className="form-control"
                  >
                    <option value="0">Any</option>
                    <option value="1">Price (High to Low)</option>
                    <option value="2">Price (Low to High)</option>
                    <option value="3">Newest</option>
                    <option value="4">Bedrooms</option>
                    <option value="5">Bathrooms</option>
                    <option value="6">Square Feet</option>
                    <option value="7">Lot Size</option>
                  </select>
                </div>
              </div>
              <p>
                <i className="fa fa-star" aria-hidden="true"></i> Save the
                Search
              </p>
            </div>
            {propertiesData.length === 0 && (
              <div className="no-listing-block">
                <figure>
                  <img src="../assets/images/no-properties-list.png" alt="" />
                </figure>
                <h5>Couldn't find any properties matching your search</h5>
              </div>
            )}

            {propertiesData.map((eachProperty) => (
              <FeatureItem key={eachProperty._id} eachProperty={eachProperty} />
            ))}
            <Pagination
              pageSize={10}
              total={115}
              showTitle={false}
              current={paginationObj.current}
              onChange={(current, pageSize) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setPaginationObj({ current: current, pageSize: pageSize });
                setPropertiesData(initialData);
                getPropertiesData(pageSize, (current - 1) * pageSize);
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default FeaturedListing;

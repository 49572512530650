import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

import "./index.css";
import { useState } from "react";

const ShareModal = ({ address, description }) => {
  const [copySuccess, setCopySuccess] = useState("Copy");

  const currentUrl = window.location.href;

  function copyToClip() {
    navigator.clipboard.writeText(currentUrl);
    setCopySuccess("Copied");
    setTimeout(() => {
      setCopySuccess("Copy");
    }, 2500);
  }

  const detailsDescribe =
    description === null ? " " : description.slice(0, 150);

  return (
    <div
      className="modal fade modal-block share-modal"
      id="shareModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content request-block">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                viewBox="0 0 32 32"
                aria-hidden="true"
                focusable="false"
                role="img"
                className="Icon-c11n-8-100-2__sc-13llmml-0 fBvdWz"
              >
                <path
                  stroke="none"
                  d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"
                ></path>
              </svg>
            </button>
            <h5>Share This Home</h5>
          </div>
          <div className="modal-body">
            <ul className="share-links">
              <li>
                <EmailShareButton url={currentUrl}>
                  <EmailIcon />
                  <label>Email</label>
                </EmailShareButton>
              </li>
              <li>
                <FacebookShareButton url={currentUrl}>
                  <FacebookIcon />
                  <label>Facebook</label>
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton url={currentUrl}>
                  <XIcon />
                  <label>Twitter</label>
                </TwitterShareButton>
              </li>
              <li>
                <WhatsappShareButton
                  url={currentUrl}
                  title={address + " " + detailsDescribe + "..."}
                  separator="
Link: "
                >
                  <WhatsappIcon />
                  <label>Whatsapp</label>
                </WhatsappShareButton>
              </li>
              <li>
                <LinkedinShareButton url={currentUrl}>
                  <LinkedinIcon />
                  <label>LinkedIn</label>
                </LinkedinShareButton>
              </li>
            </ul>
            <div className="form-group">
              <input
                disabled
                defaultValue={currentUrl}
                type="text"
                className="form-control"
              />
              <button onClick={copyToClip} type="button" className="btn">
                <svg className="bp-SvgIcon link bp-SvgIcon__size--medium">
                  <svg viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.043 10.957a3.596 3.596 0 00-5.086 0l-3 3a3.596 3.596 0 005.086 5.086l.75-.75a1 1 0 011.414 1.414l-.75.75a5.596 5.596 0 11-7.914-7.914l3-3a5.596 5.596 0 017.914 0l.25.25a1 1 0 01-1.414 1.414l-.25-.25z"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.543 3.543a5.596 5.596 0 117.914 7.914l-3 3a5.596 5.596 0 01-7.914 0l-.25-.25a1 1 0 111.414-1.414l.25.25a3.596 3.596 0 005.086 0l3-3a3.596 3.596 0 00-5.086-5.086l-.75.75a1 1 0 11-1.414-1.414l.75-.75z"
                    ></path>
                  </svg>
                </svg>
                {copySuccess}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;

import { useEffect, useState } from "react";
import blogsData from "../../blogs-list.json";
import BlogItem from "../../Components/BlogItem";
import "./index.css";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";

const LatestPostsList = ({ eachBlog }) => {
  const { id, imagUrl, title } = eachBlog;
  const [onLoad, setOnLoad] = useState(false);
  const webTitle = title.replace(/ /g, "-");

  return (
    <li>
      <Link to={`/blog/${webTitle}/${id}`}>
        <img
          className={onLoad ? "" : "skeleton-loading"}
          src={imagUrl}
          alt={title}
          onLoad={() => setOnLoad(true)}
        />
        <p className={title === null ? "skeleton-loading" : ""}>
          {title === null ? "" : title}
        </p>
      </Link>
    </li>
  );
};

const BlogDetails = () => {
  const { id } = useParams();
  const [blogDetails, setBlogDetails] = useState({
    imagUrl: null,
    date: null,
    title: null,
    description: null,
    writtenBy: null,
  });
  const [onLoad, setOnLoad] = useState(false);

  useEffect(() => {
    const blogData = blogsData.find((eachBlog) => eachBlog.id === Number(id));
    setBlogDetails(blogData);
    document.title = blogData?.title || "Blog Details";
    window.scrollTo({ top: 0 });
  }, [id]);

  const { imagUrl, date, title, description, writtenBy } = blogDetails;
  const randomNum = Math.floor(Math.random() * (blogsData.length - 3)) + 3;

  const sortedBlogsData = _.sortBy(blogsData, "date").reverse();

  return (
    <section className="blog-details">
      <div className="container-fluid">
        <div className="blog-details-block">
          <div className="right-block">
            <h5>
              <span>Latest</span> Posts
            </h5>
            <ul className="latest-posts-list">
              {sortedBlogsData.slice(0, 5).map((eachBlog) => (
                <LatestPostsList key={eachBlog.id} eachBlog={eachBlog} />
              ))}
            </ul>
          </div>
          <div className="left-block">
            <figure className={onLoad ? "" : "skeleton-loading"}>
              <img
                onLoad={() => setOnLoad(true)}
                src={imagUrl === null ? "" : imagUrl}
                alt={title}
              />
            </figure>
            <ul className="details-list">
              <li className={date === null ? "skeleton-loading" : ""}>
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                {date}
              </li>
              <li className={writtenBy === null ? "skeleton-loading" : ""}>
                <i className="fa fa-user" aria-hidden="true"></i>
                {writtenBy}
              </li>
            </ul>
            <h4 className={title === null ? "skeleton-loading" : ""}>
              {title === null ? "" : title}
            </h4>
            <div
              className={
                description === null
                  ? "text-content skeleton-loading"
                  : "text-content"
              }
              dangerouslySetInnerHTML={
                description === null ? { __html: "" } : { __html: description }
              }
            />
            <div className="blogs-page blog">
              <h5>
                <span>Related</span> Blogs
              </h5>
              <div className="blog-main">
                {blogsData.slice(randomNum - 3, randomNum).map((eachBlog) => (
                  <BlogItem key={eachBlog.id} eachBlog={eachBlog} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;

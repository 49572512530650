import { useEffect, useState } from "react";
import BlogItem from "../../Components/BlogItem";
import initialBlogsData from "../../blogs-list.json";
import Pagination from "rc-pagination/lib/Pagination";

const Blogs = () => {
  const [paginationObj, setPaginationObj] = useState({
    current: 1,
    pageSize: 12,
  });
  const [blogsData, setBlogsData] = useState([]);

  const onUpdateData = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    onUpdateData();
    setBlogsData(initialBlogsData);
    document.title = "Selzar Reality";
  }, []);

  return (
    <section className="blogs-page blog">
      <div className="container-fluid">
        <h2>Our Blog</h2>
        <p>Watch other’s Think about Us</p>
        <div className="blog-main">
          {blogsData
            .slice(
              paginationObj.pageSize * paginationObj.current -
                paginationObj.pageSize,
              paginationObj.pageSize * paginationObj.current
            )
            .map((eachBlog) => (
              <BlogItem key={eachBlog.id} eachBlog={eachBlog} />
            ))}
        </div>
      </div>
      {blogsData.length > paginationObj.pageSize && (
        <Pagination
          pageSize={paginationObj.pageSize}
          total={blogsData.length}
          showTitle={false}
          onChange={(current, pageSize) => {
            setPaginationObj({ current: current, pageSize: pageSize });
            onUpdateData();
          }}
        />
      )}
    </section>
  );
};

export default Blogs;

import { Link } from "react-router-dom";
import PropertyDetails from "../../Pages/PropertyDetails";
import "./index.css";

const PropertyDetailQuickView = ({ activeModalId, setActiveModalId }) => {
  return (
    <div
      className="modal fade modal-block"
      id="propertyDetailQuickView"
      tabIndex="-1"
      aria-labelledby="propertyDetailQuickViewLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        {activeModalId.id && (
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setActiveModalId({ id: null, address: "" });
                }}
              >
                <svg
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="Icon-c11n-8-100-2__sc-13llmml-0 fBvdWz"
                >
                  <path
                    stroke="none"
                    d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"
                  ></path>
                </svg>
              </button>
              <h5>{activeModalId.address}</h5>
            </div>
            <div className="modal-body">
              <PropertyDetails propertyId={activeModalId.id} isPopup={true} />
              <Link
                target="_blank"
                className="btn"
                to={`/property-details/${activeModalId.id}`}
              >
                View More
              </Link>
            </div>
            <div className="modal-footer">
              <button
                className="btn"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#request-btn"
              >
                Request a Tour
              </button>
              <button
                type="button"
                className="btn bordered-btn"
                data-bs-toggle="modal"
                data-bs-target="#contact-btn"
              >
                Contact Agent
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyDetailQuickView;

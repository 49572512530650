import OwlCarousel from "react-owl-carousel-autoheight";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from "jquery";
import { addDays, format } from "date-fns";
import { useState } from "react";

const DatesList = ({ eachDate, isActive }) => {
  return (
    <div className="form-group">
      <input
        value={eachDate.dayOfWeek + ", " + eachDate.month + " " + eachDate.date}
        type="radio"
        name="date"
        defaultChecked={isActive}
      />
      <label>
        {eachDate.dayOfWeek.slice(0, 3)}{" "}
        <b>
          {eachDate.date.slice(0, -2).length === 1 && 0}
          {eachDate.date.slice(0, -2)}
        </b>{" "}
        {eachDate.month.slice(0, 3)}
      </label>
    </div>
  );
};

const ContactModal = ({ title }) => {
  let dateArr = [];
  const date = new Date();
  for (let i = 0; i < 9; i++) {
    const tomorrow = addDays(date, i);
    const dateObj = {
      month: format(tomorrow, "MMMM"),
      dayOfWeek: format(tomorrow, "iiii"),
      date: format(tomorrow, "do"),
    };
    dateArr = [...dateArr, dateObj];
  }

  const [selectedDate, setSelectedDate] = useState("");

  const dateChange = () => {
    const arr = document.querySelectorAll(
      ".request-block-modal .request-block .calendar-list-carousel .form-group input:checked"
    );
    setSelectedDate(arr[0].value);
    $(".request-block-modal .request-block").removeClass("active");
    $(".request-block-modal .confirm-block-content").addClass("active");
  };

  const onCloseBlock = () => {
    setSelectedDate(
      dateArr[0].dayOfWeek + ", " + dateArr[0].month + " " + dateArr[0].date
    );
    setTimeout(() => {
      $(".request-block-modal .request-block").addClass("active");
      $(".request-block-modal .confirm-block-content").removeClass("active");
    }, 100);
  };

  return (
    <div
      className="modal fade modal-block request-block-modal "
      id="request-btn"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content request-block active">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onCloseBlock}
            >
              <svg
                viewBox="0 0 32 32"
                aria-hidden="true"
                focusable="false"
                role="img"
                className="Icon-c11n-8-100-2__sc-13llmml-0 fBvdWz"
              >
                <path
                  stroke="none"
                  d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"
                ></path>
              </svg>
            </button>
            <h5>{title}</h5>
          </div>
          <div className="modal-body">
            <p>
              A licensed real estate professional from EXR will reach out
              regarding your tour request.
            </p>
            <strong>
              <em>• DATE</em>
            </strong>
            <OwlCarousel
              className="owl-carousel calendar-list-carousel"
              nav={true}
              dots={false}
              margin={16}
              items={5}
              responsive={{
                0: {
                  items: 4,
                },
                376: {
                  items: 5,
                  margin: 10,
                },
                480: {
                  items: 5,
                  margin: 16,
                },
              }}
            >
              {dateArr.map((eachDate, i) => (
                <DatesList
                  eachDate={eachDate}
                  isActive={i === 0}
                  key={eachDate.date}
                />
              ))}
            </OwlCarousel>
            <strong>
              <em>• TIME</em>
            </strong>
            <ul className="time-list">
              <li className="form-group">
                <input
                  type="radio"
                  value="Morning"
                  name="time"
                  defaultChecked
                />
                <label>
                  Morning <b>8AM - 12PM</b>
                </label>
              </li>
              <li className="form-group">
                <input type="radio" value="Afternoon" name="time" />
                <label>
                  Afternoon <b>12AM - 5PM</b>
                </label>
              </li>
              <li className="form-group">
                <input type="radio" value="Evening" name="time" />
                <label>
                  Evening <b>5AM - 9PM</b>
                </label>
              </li>
            </ul>
            <strong>
              <em>• Remote Tour</em>
            </strong>
            <p>
              Tour this listing remotely through Face time, Google, Hangout,
              What app or Skype.
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" onClick={dateChange} className="btn">
              Continue
            </button>
          </div>
        </div>
        <div className="modal-content confirm-block-content">
          <form>
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCloseBlock}
              >
                <svg
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  className="Icon-c11n-8-100-2__sc-13llmml-0 fBvdWz"
                >
                  <path
                    stroke="none"
                    d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"
                  ></path>
                </svg>
              </button>
              <h5>Confirm Your Tour</h5>
            </div>
            <div className="modal-body">
              <p>
                You are requesting a tour on
                <strong style={{ fontWeight: 600 }}>
                  {" "}
                  {selectedDate}, as soon as possible.
                </strong>
              </p>

              <div className="form-group">
                <label className="req-label">Name</label>
                <input required className="form-control" type="text" />
              </div>
              <div className="form-group">
                <label className="req-label">Email</label>
                <input required className="form-control" type="email" />
              </div>
              <div className="form-group">
                <label className="req-label">Phone</label>
                <input required className="form-control" type="text" />
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea className="form-control"></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn">
                Confirm Your Tour
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;

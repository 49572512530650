import { useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-autoheight";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const PropertyGallery = ({ img, id }) => {
  const [onLoad, setOnLoad] = useState(false);

  return (
    <Link target="_blank" to={`/property-details/${id}`}>
      <figure className={onLoad ? "" : "skeleton-loading"}>
        <img
          src={img.sizes?.thumb}
          loading="lazy"
          onLoad={() => {
            setOnLoad(true);
          }}
          alt=""
        />
      </figure>
    </Link>
  );
};

const FeatureItem = ({ eachProperty }) => {
  const {
    image,
    address,
    price,
    priceData,
    _id,
    bedrooms,
    totalBaths,
    sqFt,
    remarksConcat,
  } = eachProperty;
  const [onLoad, setOnLoad] = useState(false);

  function formatToUSAPrice(number) {
    return number
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .slice(0, -3);
  }

  const picturesList = image ? Object.values(image) : [{}];

  return (
    <div className="feature-item">
      <div className="feature-item-left">
        <OwlCarousel
          className="owl-carousel property-item-slider"
          nav={true}
          dots={false}
          margin={10}
          lazyLoad={true}
          items={1}
        >
          {picturesList.map((eachImg, i) => (
            <PropertyGallery key={i} img={eachImg} id={_id} />
          ))}
        </OwlCarousel>
      </div>
      <div className="feature-item-right">
        <h5 className={_id !== null ? "" : "skeleton-loading"}>{address}</h5>
        <h4 className={_id !== null ? "" : "skeleton-loading"}>
          {priceData.value?.formatted} <span>(For Sale)</span>
        </h4>
        <h6>
          <span className={_id !== null ? "" : "skeleton-loading"}>
            Listing ID: <span>{_id}</span>
          </span>
          <a href=" " className={_id !== null ? "" : "skeleton-loading"}>
            Add to Favorites
            <i className="fa fa-heart-o" aria-hidden="true"></i>
          </a>
        </h6>

        <div className="feature-bottom">
          <ul>
            <li className={_id !== null ? "" : "skeleton-loading"}>
              <i className="fa fa-bed" aria-hidden="true"></i> {bedrooms}
            </li>
            <li className={_id !== null ? "" : "skeleton-loading"}>
              <i className="fa fa-shower" aria-hidden="true"></i> {totalBaths}
            </li>
            <li className={_id !== null ? "" : "skeleton-loading"}>
              <i className="fa fa-crop" aria-hidden="true"></i> {sqFt} SqFt
            </li>
          </ul>
        </div>
        <p
          className={_id !== null ? "" : "skeleton-loading"}
          dangerouslySetInnerHTML={
            remarksConcat === null
              ? { __html: "" }
              : { __html: remarksConcat.slice(0, 200) + "..." }
          }
        />
        <Link
          target="_blank"
          to={`/property-details/${_id}`}
          className={_id !== null ? "btn" : "btn skeleton-loading"}
        >
          View Details
        </Link>
      </div>
    </div>
  );
};

export default FeatureItem;

import { Link } from "react-router-dom";
import "./index.css";

function Footer() {
  return (
    <footer>
      <div className="footer-logo">
        <figure>
          <img src="../assets/images/bottom-main-logo.png" alt="" />{" "}
        </figure>
      </div>
      <div className="footer-block">
        <div className="container-fluid">
          <div className="row contact">
            <div className="col-sm-6 col-md-4 col-lg-3 address">
              <h5>Address</h5>
              <p>551 South Orchard Avenue Ukiah, CA 95482</p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 phone">
              <h5>Phone</h5>
              <a href="tel:+18007363119">(800) 736-3119</a>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mail">
              <h5>Email</h5>
              <a href="mailto:info@selzerrealty.com">info@selzerrealty.com</a>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 social-icons">
              <h5>Social Media</h5>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/SelzerRealty/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/realty-world-selzer-realty/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/RWSelzer"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/selzerrealty/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <u></u>
          <div className="row contact-bottom">
            <div className="col-sm-6 col-md-4 col-lg-3 about">
              <h5>About</h5>
              <p>
                Selzer Realty & Associates is the largest independent,
                full-service real estate team in Lake and Mendocino Counties,
                offering you professional assistance whether you plan to sell,
                rent or buy a home or other property.
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 company">
              <h5>Company Info</h5>
              <ul>
                <li>
                  <a href="#">Equal Housing</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Listings Sitemap</a>
                </li>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Real Estate Terms and Definitions</a>
                </li>
                <li>
                  <a href="#">Indianapolis Area Market Reports</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 company links">
              <h5>Use Full Links</h5>
              <ul>
                <li>
                  <a href="#">City</a>
                </li>
                <li>
                  <a href="#">County</a>
                </li>
                <li>
                  <a href="#">Township</a>
                </li>
                <li>
                  <a href="#">School</a>
                </li>
                <li>
                  <a href="#">Subdivision</a>
                </li>
                <li>
                  <a href="#">Zip Code</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 newsletter">
              <h5>News Letter</h5>
              <p>
                Join our monthly newsletter to receive valuable information and
                tips on today's markets.
              </p>
              <form className="search-block">
                <input
                  type="email"
                  placeholder="Email Address"
                  className="form-control"
                />
                <button>
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright-block">
            <p>
              The information provided herein is supplied by several sources and
              is subject to change without notice. The owner of this website
              does not guarantee or is in any way responsible for its accuracy,
              and provides said information without warranties of any kind,
              either expressed or implied. Should you have any questions,
              concerns, or needs, please feel free to contact the owner by
              visiting the contact page.
            </p>
            <p>
              {" "}
              &#169; 2024 <a href="#">Selzer Realty & Associates</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import { useEffect, useState } from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel-autoheight";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { v4 as uuidv4 } from "uuid";
import Banner from "../../Components/Banner";
import initialBlogsData from "../../blogs-list.json";
import PropertyItem from "../../Components/PropertyItem";
import NeighborhoodItem from "../../Components/NeighborhoodItem";
import BlogItem from "../../Components/BlogItem";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ContactModal from "../../Components/ContactModal";
import "./index.css";
import { Link } from "react-router-dom";
import { PROPERTIES_URL } from "../../Global/API's";

const neighborhoodItems = [
  {
    id: uuidv4(),
    imgUrl: "./assets/images/neighborhood-img1.jpg",
    location: "Ukiah",
  },
  {
    id: uuidv4(),
    imgUrl: "./assets/images/neighborhood-img2.jpg",
    location: "Willits",
  },
  {
    id: uuidv4(),
    imgUrl: "./assets/images/neighborhood-img3.jpg",
    location: "Laytonville",
  },
];

const CommunityPartnersList = [
  { id: uuidv4(), img: "./assets/images/our-partners-img1.jpg" },
  { id: uuidv4(), img: "./assets/images/our-partners-img2.jpg" },
  { id: uuidv4(), img: "./assets/images/our-partners-img3.jpg" },
  { id: uuidv4(), img: "./assets/images/our-partners-img4.jpg" },
  { id: uuidv4(), img: "./assets/images/our-partners-img5.jpg" },
  { id: uuidv4(), img: "./assets/images/our-partners-img6.jpg" },
];

const CommunityPartnersItem = ({ img }) => {
  return (
    <div className="item">
      <a href=" ">
        <figure>
          <img src={img} alt="" />
        </figure>
      </a>
    </div>
  );
};

ChartJS.register(Tooltip, ArcElement);

const Home = () => {
  const [formData, setFormData] = useState({
    purchasePrice: 500000,
    downPayment: 50,
    propertyTaxRate: 1.2,
    loanTerm: 15,
    intrestRate: 4,
    insuranceRate: 0.21,
  });
  const [mortageData, setMortageData] = useState({
    totalInterest: 833,
    totalprincipal: 1016,
    monthlyPropertyTax: 500,
    monthlyInsurance: 88,
    totalMonthlyPayment: formatToUSAPrice(2437),
  });
  const [featuredItems, setFeaturedItems] = useState(null);
  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
    setBlogsData(initialBlogsData);
    getPropertiesData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function formatToUSAPrice(number) {
    return number
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .slice(0, -3);
  }

  const calculateMortage = (e) => {
    e.preventDefault();
    const {
      purchasePrice,
      downPayment,
      propertyTaxRate,
      loanTerm,
      intrestRate,
      insuranceRate,
    } = formData;
    const downPaymentValue =
      Number(formData.purchasePrice) * (Number(downPayment) / 100);
    const loanmount = purchasePrice - downPaymentValue;
    const monthlyInterestRate = intrestRate / 100 / 12;
    const totalPayments = loanTerm * 12;
    const monthlyMortgage =
      (loanmount *
        (monthlyInterestRate * (1 + monthlyInterestRate) ** totalPayments)) /
      ((1 + monthlyInterestRate) ** totalPayments - 1);
    const totalInterest = Math.round(loanmount * monthlyInterestRate);
    const totalprincipal = Math.round(monthlyMortgage - totalInterest);
    const monthlyPropertyTax = Math.round(
      (purchasePrice * (propertyTaxRate / 100)) / 12
    );
    const monthlyInsurance = Math.round(
      (purchasePrice * (insuranceRate / 100)) / 12
    );
    const totalMonthlyPayment = formatToUSAPrice(
      Math.round(monthlyMortgage + monthlyPropertyTax + monthlyInsurance)
    );
    setMortageData({
      totalInterest,
      totalprincipal,
      monthlyPropertyTax,
      monthlyInsurance,
      totalMonthlyPayment,
    });
  };

  const data = {
    labels: ["Interest", "Home Insurance", "Property Tax", "Principal"],
    datasets: [
      {
        label: "$",
        data: [
          mortageData.totalInterest,
          mortageData.monthlyInsurance,
          mortageData.monthlyPropertyTax,
          mortageData.totalprincipal,
        ],
        backgroundColor: [
          "#1b68b8",
          "#e9484d",
          "#fdbc3e",
          "rgba(75, 192, 192, 1)",
        ],
        hoverOffset: 10,
        cutout: 125,
        borderWidth: 10,
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    responsive: true,
  };

  const getPropertiesData = async () => {
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const url = `${PROPERTIES_URL}?limit=10&skip=0`;
    const response = await fetch(url, options);
    if (response.ok) {
      const data = await response.json();
      const filteredData = data.filter((property) => property.featured === "y");
      setFeaturedItems(filteredData.slice(0, 8));
    }
  };

  const renderLoadingView = () => {
    return (
      <div className="loader">
        <div className="loading-two"></div>
      </div>
    );
  };

  return (
    <>
      <Banner />
      <section className="featured-main">
        <div className="container-fluid">
          <h2>Featured Listings</h2>
          <p>Looking for the perfect property? We can help!</p>
          <ul className="properties-list-items">
            {featuredItems !== null
              ? featuredItems.map((eachProperty) => (
                  <PropertyItem
                    key={eachProperty._id}
                    eachProperty={eachProperty}
                  />
                ))
              : renderLoadingView()}
          </ul>
          <div className="featured-main-bottom">
            <Link to="/featured-listing" className="btn btn-bottom-bordered">
              View More
            </Link>
          </div>
        </div>
      </section>
      <section className="why-selzer">
        <div className="container-fluid">
          <h2>Why Selzer Realty & Associates? </h2>
          <p>Our real estate sales team sells far more properties.</p>
          <div className="why-selzer-block">
            <div className="why-selzer-left">
              <figure className="why-selzer-figure1">
                <img src="../assets/images/why-selzer-img-1.jpg" alt="" />
              </figure>
              <figure className="why-selzer-figure2">
                <img src="../assets/images/why-selzer-img-2.jpg" alt="" />
              </figure>
            </div>
            <div className="why-selzer-right">
              <p>
                Selzer Realty & Associates is the largest independent,
                full-service real estate team in Lake and Mendocino Counties,
                offering you professional assistance whether you plan to sell,
                rent or buy a home or other property.
              </p>
              <h5>Over $100 million sold per year</h5>
              <p>
                In 2020, we represented either the buyer or seller (or both) in
                almost a quarter of all real estate transactions from Hopland to
                Laytonville.
                <strong>
                  Our closest competitor represented less than 10 percent.
                  (Percentage based on dollar volume. Source: BAREIS MLS,
                  Mendocino County, 2020)
                </strong>
              </p>
              <h5>Leading the industry</h5>
              <p>
                <strong>
                  When it comes to sales volume, we sell more than our top 3
                  competitors combined.
                </strong>
                In 2020 in the Ukiah Valley, the dollar value of our Realtors’
                transactions totaled $78,735,360 – almost a third of all real
                estate transactions. (Source: BAREIS MLS, Mendocino County,
                2020).
              </p>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#request-btn"
                className="btn btn-bottom-bordered"
              >
                Book an Appointment
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="neighborhood">
        <h2>Find the Neighborhood For You</h2>
        <p>
          The neighborhoods best suited to your lifestyle, and the agents who
          know them best.
        </p>
        <div className="container-fluid">
          <div className="neighborhood-main">
            {neighborhoodItems.map((eachLocation) => (
              <NeighborhoodItem
                key={eachLocation.id}
                eachLocation={eachLocation}
              />
            ))}
          </div>

          <div className="neighborhood-bottom">
            <a href=" " className="btn btn-bottom-bordered">
              Explore More
            </a>
          </div>
        </div>
      </section>
      <section className="mortgage-calculator-main">
        <div className="container-fluid">
          <h2>Mortgage Calculator</h2>
          <p>Wondering what you can afford? Enter your information below.</p>
          <h6>Get Started with the Digital Mortgage Experience</h6>
          <form onSubmit={calculateMortage}>
            <div className="mortgage-calculator-block">
              <div className="mortgage-calculator-graph">
                <div className="each-score">
                  <label>
                    Purchase Price
                    <span>
                      {formatToUSAPrice(Number(formData.purchasePrice))}
                    </span>
                  </label>
                  <input
                    name="purchasePrice"
                    type="range"
                    min="0"
                    max="1000000"
                    step="1000"
                    value={formData.purchasePrice}
                    onChange={handleChange}
                  />
                  <p>
                    $50,000 <span>$1,000,000</span>
                  </p>
                </div>
                <div className="each-score">
                  <label>
                    {"Down payment (" + Number(formData.downPayment) + ")%"}
                    <span>
                      {formatToUSAPrice(
                        Number(formData.purchasePrice) *
                          (Number(formData.downPayment) / 100)
                      )}
                    </span>
                  </label>
                  <input
                    name="downPayment"
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={formData.downPayment}
                    onChange={handleChange}
                  />
                  <p>
                    0% <span>100%</span>
                  </p>
                </div>
                <div className="each-score">
                  <label>
                    Property Tax Rate<span>{formData.propertyTaxRate} %</span>
                  </label>
                  <input
                    name="propertyTaxRate"
                    type="range"
                    min="0"
                    max="5"
                    step="0.01"
                    value={formData.propertyTaxRate}
                    onChange={handleChange}
                  />
                  <p>5% of more of purchase price</p>
                </div>
              </div>
              <div className="mortgage-calculator-graph">
                <div className="each-score top">
                  <label>
                    Loan Term <span>{formData.loanTerm} yrs</span>
                  </label>
                  <input
                    name="loanTerm"
                    type="range"
                    min="0"
                    max="30"
                    step="1"
                    value={formData.loanTerm}
                    onChange={handleChange}
                  />
                </div>
                <div className="each-score">
                  <label>
                    Interest Rate<span>{formData.intrestRate} %</span>
                  </label>
                  <input
                    name="intrestRate"
                    type="range"
                    min="1"
                    max="12"
                    step="0.01"
                    value={formData.intrestRate}
                    onChange={handleChange}
                  />
                  <p>5% of more of purchase price</p>
                </div>
                <div className="each-score">
                  <label>
                    Insurance Rate<span>{formData.insuranceRate} %</span>
                  </label>
                  <input
                    name="insuranceRate"
                    type="range"
                    min="0"
                    max="5"
                    step="0.01"
                    value={formData.insuranceRate}
                    onChange={handleChange}
                  />
                  <p>5% of more of purchase price</p>
                </div>
              </div>
              <div className="mortgage-calculator-right-block">
                <div className="chart-block">
                  <Doughnut options={options} data={data} />
                  <p>
                    Your First Payment
                    <span>{mortageData.totalMonthlyPayment}</span>
                  </p>
                </div>
                <p>
                  If you'd like a more specific estimate, click here so we can
                  help you find the right resource.
                </p>
              </div>
            </div>
            <div className="mortgage-calculator-bottom">
              <button type="submit" className="btn btn-bottom-bordered">
                Calculate
              </button>
            </div>
          </form>
        </div>
      </section>
      <section className="our-partners">
        <div className="container-fluid">
          <h2>Community Partners</h2>
          <p>We work with community partners so things go smoothly for you.</p>
          <OwlCarousel
            className="our-partners-slider"
            loop={true}
            nav={false}
            dots={false}
            autoplay={true}
            autoplayHoverPause={true}
            autoplayTimeout="2000"
            items={6}
            responsive={{
              0: {
                margin: 15,
                items: 2,
              },
              376: {
                margin: 20,
                items: 3,
              },
              480: {
                margin: 40,
                items: 3,
              },
              600: {
                margin: 50,
                items: 4,
              },
              767: {
                margin: 60,
                items: 5,
              },
              992: {
                margin: 80,
                items: 6,
              },
              1200: {
                margin: 90,
                items: 6,
              },
              1400: {
                margin: 110,
                items: 6,
              },
            }}
          >
            {CommunityPartnersList.map((eachItem) => (
              <CommunityPartnersItem img={eachItem.img} key={eachItem.id} />
            ))}
          </OwlCarousel>
        </div>
      </section>
      <section className="blog">
        <div className="container">
          <h2>Our Blog</h2>
          <p>Watch other’s Think about Us</p>
          <div className="blog-main">
            {blogsData.slice(0, 5).map((eachBlog) => (
              <BlogItem key={eachBlog.id} eachBlog={eachBlog} />
            ))}
          </div>
          <Link to="/blog" className="btn btn-bottom-bordered">
            View More
          </Link>
        </div>
      </section>

      <ContactModal title="Book an Appointment" />
    </>
  );
};

export default Home;

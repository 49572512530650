import { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Autocomplete } from "@react-google-maps/api";

const Banner = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState("Result: none");
  const [propertyType, setPropertyType] = useState([
    "Any",
    "Single Family Home",
    "Multi-Family",
    "Condo",
    "Townhouse",
    "Mobile Home",
    "Manufactured Home",
    "Vacant Land",
    "Commercial",
    "Other Rentals",
  ]);
  const navigate = useNavigate();

  const onDataSubmit = () => {
    navigate("./properties-for-sale", {
      state: {
        searchValue,
        priceFrom: "",
        priceTo: "",
        propertyType,
      },
    });
  };

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const formattedAddress = place.formatted_address;
      setSearchValue(formattedAddress);
    }
  }
  return (
    <section className="banner">
      <div className="item">
        <div className="container">
          <div className="banner-block">
            <h1>
              Find your Home in <strong>Selzer realty & Associates</strong>
            </h1>
          </div>
          <div className="Search-block">
            <form>
              <div className="form-group">
                <label>Location</label>
                <Autocomplete
                  className="form-field"
                  onPlaceChanged={onPlaceChanged}
                  onLoad={onLoad}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Location"
                  />
                </Autocomplete>
              </div>
              <div className="form-group">
                <label>Type</label>
                <select
                  onChange={(e) => {
                    if (e.target.value === "Any") {
                      setPropertyType([
                        "Any",
                        "Single Family Home",
                        "Multi-Family",
                        "Condo",
                        "Townhouse",
                        "Mobile Home",
                        "Manufactured Home",
                        "Vacant Land",
                        "Commercial",
                        "Other Rentals",
                      ]);
                    } else {
                      setPropertyType([e.target.value]);
                    }
                  }}
                  defaultValue="0"
                  className="form-control"
                >
                  <option value="0" disabled>
                    Property Type
                  </option>
                  <option value="Any">Any</option>
                  <option value="Single Family Home">Single Family Home</option>
                  <option value="Multi Family">Multi-Family</option>
                  <option value="Condo">Condo</option>
                  <option value="Townhouse">Townhouse</option>
                  <option value="Mobile Home">Mobile Home</option>
                  <option value="Manufactured Home">Manufactured Home</option>
                  <option value="Vacant Land">Vacant Land</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Other Rentals">Other Rentals</option>
                </select>
              </div>
              <div className="form-group">
                <label>Category</label>
                <select className="form-control">
                  <option>Property Category</option>
                  <option>Property Category-1</option>
                  <option>Property Category-2</option>
                  <option>Property Category-3</option>
                </select>
              </div>
              <button className="btn" onClick={onDataSubmit} type="submit">
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;

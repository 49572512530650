import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "jquery-match-height/dist/jquery.matchHeight-min.js";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import BuyProperties from "./Pages/BuyProperties";
import PropertyDetails from "./Pages/PropertyDetails";
import Blogs from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import "../node_modules/fontawesome-4.7/css/font-awesome.min.css";
import "./responsive.css";
import FeaturedListing from "./Pages/FeaturedListing";

const App = () => {
  const [isPropertiesPage, setIsPropertiesPage] = useState(false);

  const isActivePropertiesPage = () => {
    setIsPropertiesPage(true);
  };

  const isInActivePropertiesPage = () => {
    setIsPropertiesPage(false);
  };

  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/properties-for-sale"
          element={
            <BuyProperties
              isInActivePropertiesPage={isInActivePropertiesPage}
              isActivePropertiesPage={isActivePropertiesPage}
            />
          }
        />
        <Route
          exact
          path="/property-details/:id"
          element={<PropertyDetails />}
        />
        <Route exact path="/blog" element={<Blogs />} />
        <Route exact path="/featured-listing" element={<FeaturedListing />} />
        <Route exact path="/blog/:title/:id" element={<BlogDetails />} />
      </Routes>
      {!isPropertiesPage && <Footer />}
    </>
  );
};

export default App;

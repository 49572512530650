/* eslint-disable react-hooks/exhaustive-deps */
import OwlCarousel from "react-owl-carousel-autoheight";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import "magnific-popup/dist/jquery.magnific-popup.min.js";
import "magnific-popup/dist/magnific-popup.css";
import "./index.css";
import {
  Autocomplete,
  DirectionsRenderer,
  GoogleMap,
  MarkerF,
} from "@react-google-maps/api";
import ContactModal from "../../Components/ContactModal";
import ShareModal from "../../Components/ShareModal";
import PropertyItem from "../../Components/PropertyItem";
import { PROPERTY_DETAIL_URL } from "../../Global/API's";

const PropertyGallery = ({ img }) => {
  const [onLoad, setOnLoad] = useState(false);

  return (
    <div className="item">
      <a className="popup-image" href={img.sizes?.thumb}>
        <figure className={onLoad ? "" : "skeleton-loading"}>
          <img
            src={img.sizes?.thumb}
            loading="lazy"
            onLoad={() => {
              setOnLoad(true);
            }}
            alt=""
          />
        </figure>
      </a>
    </div>
  );
};

function PropertyDetails({ propertyId, isPopup = false }) {
  const { id } = useParams();
  const activeId = id ? id : propertyId;
  const destinationRef = useRef();
  const locationBlock = useRef(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [directionsErr, setDirectionsErr] = useState(false);
  const [travelMode, setTravelMode] = useState("DRIVING");
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [propertiesData, setPropertiesData] = useState(null);

  const getPropertyData = async () => {
    const url = `${PROPERTY_DETAIL_URL}/${activeId}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, options);
    if (response.ok) {
      const data = await response.json();
      setPropertiesData(data);
    }
  };

  useEffect(() => {
    getPropertyData();
  }, [activeId]);

  useEffect(() => {
    $(".property-details .popup-image").magnificPopup({
      type: "image",
      gallery: {
        enabled: true,
      },
      mainClass: "mfp-fade",
      removalDelay: 300,
      zoom: {
        enabled: true,
        duration: 300,
        opener: function (element) {
          return element.find("img");
        },
      },
    });
  });

  const renderPropertyDetails = () => {
    const {
      image,
      address,
      cityName,
      state,
      price,
      bedrooms,
      totalBaths,
      propType,
      remarksConcat,
      propSubType,
      propStatus,
      listingID,
      sqFt,
      fullBaths,
      halfBaths,
      latitude,
      longitude,
      advanced,
    } = propertiesData;

    async function calculateRoute() {
      if (destinationRef.current.value === "" || address === "") {
        return;
      }
      function getTravleMode() {
        if (travelMode === "DRIVING") {
          // eslint-disable-next-line no-undef
          return google.maps.TravelMode.DRIVING;
        } else if (travelMode === "TRANSIT") {
          // eslint-disable-next-line no-undef
          return google.maps.TravelMode.TRANSIT;
        } else if (travelMode === "WALKING") {
          // eslint-disable-next-line no-undef
          return google.maps.TravelMode.WALKING;
        } else if (travelMode === "CYCLING") {
          // eslint-disable-next-line no-undef
          return google.maps.TravelMode.BICYCLING;
        }
      }
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      try {
        const results = await directionsService.route({
          origin: `${address} ${cityName}, ${state}`,
          destination: destinationRef.current.value,
          travelMode: getTravleMode(),
        });
        setDirectionsResponse(results);
        setDirectionsErr(false);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      } catch (err) {
        setDirectionsErr(true);
      }
    }

    const onTransportationTypeChange = (e) => {
      setTravelMode(e.target.value);
      setDirectionsResponse(null);
      setDistance("");
      setDuration("");
    };

    const picturesList = Object.values(image).slice(0, -1);

    const scrollToSection = (elementRef) => {
      window.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: "smooth",
      });
    };

    function calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = (lat2 - lat1) * (Math.PI / 180);
      const dLon = (lon2 - lon1) * (Math.PI / 180);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) *
          Math.cos(lat2 * (Math.PI / 180)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c; // Distance in kilometers
      return distance;
    }

    let nearbyLocationsArr = [];

    // for (let eachProperty of propertiesList.listings) {
    //   const dist = calculateDistance(
    //     latitude,
    //     longitude,
    //     eachProperty.latitude,
    //     eachProperty.longitude
    //   );
    //   if (dist !== 0) {
    //     nearbyLocationsArr = [
    //       ...nearbyLocationsArr,
    //       {
    //         eachProperty,
    //         distance: dist,
    //       },
    //     ];
    //   }
    // }

    function formatToUSAPrice(number) {
      return number
        .toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
        .slice(0, -3);
    }
    nearbyLocationsArr.sort((a, b) => a.distance - b.distance);

    function convertCamelCaseToFormattedString(str) {
      const spacedString = str.replace(/([a-z])([A-Z])/g, "$1 $2");

      return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
    }
    const advaneOptions = Object.entries(advanced).map(([key, value]) => ({
      key: convertCamelCaseToFormattedString(key),
      value: Array.isArray(value) ? value.join(", ") : value,
    }));

    return (
      <>
        <section className="property-details">
          <div className="property-gallery">
            <OwlCarousel
              className="owl-carousel property-gallery-slider"
              nav={true}
              dots={false}
              margin={10}
              lazyLoad={true}
              responsiveClass={true}
              responsive={
                isPopup
                  ? {
                      0: {
                        items: 1,
                      },
                    }
                  : {
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 2,
                      },
                      992: {
                        items: 2,
                      },
                      1200: {
                        items: picturesList.length >= 3 ? 3 : 2,
                      },
                    }
              }
            >
              {picturesList.map((img, i) => (
                <PropertyGallery key={i} img={img} />
              ))}
            </OwlCarousel>
          </div>
          {!isPopup && (
            <div className="tab-list">
              <div className="container-fluid">
                <ul>
                  <li>
                    <img src="./assets/images/img-icon.png" alt="" />
                    <a>Photos ({picturesList.length})</a>
                  </li>
                  <li>
                    <img src="./assets/images/floor-icon.png" alt="" />
                    <a>Floor Plan</a>
                  </li>
                  <li>
                    <img src="./assets/images/pin-location-icon.png" alt="" />
                    <a onClick={() => scrollToSection(locationBlock)}>Map</a>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div className="container-fluid">
            <div className="property-details-block">
              {!isPopup && (
                <div className="right-block">
                  <ul className="action-list">
                    <li>
                      <button>
                        <i className="fa fa-heart-o"></i>Save
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#shareModal"
                      >
                        <i className="fa fa-share-alt"></i>Share
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => {
                          window.print();
                        }}
                      >
                        <i className="fa fa-print"></i>Print
                      </button>
                    </li>
                  </ul>
                  <div className="profile-block">
                    <figure>
                      <img src="./assets/images/profile-img.jpg" alt="" />
                    </figure>
                    <div className="text-content">
                      <h5>Richard P.Selzer</h5>
                      <h6>Broker/Owner</h6>
                      <a href="tel:+17074624000">
                        Office Phone: (707) 462-4000
                      </a>
                      <a className="mail" href="mailto:info@selzerrealty.com">
                        Email Me
                      </a>
                    </div>
                  </div>

                  <div className="btn-grp">
                    <button
                      className="btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#request-btn"
                    >
                      Request a Tour
                    </button>
                    <button
                      type="button"
                      className="btn bordered-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#contact-btn"
                    >
                      Contact Agent
                    </button>
                  </div>
                  <div className="notes-block">
                    <a href=" ">
                      <i className="fa fa-plus"></i> Add Notes To This Listings
                    </a>
                  </div>
                </div>
              )}
              <div className="left-block">
                <div className="each-block">
                  <div className="content-block">
                    <h3 className="price">
                      {formatToUSAPrice(Number(price))}{" "}
                      {/* <button type="button">
                      <i className="fa fa-calculator"></i>
                    </button> */}
                    </h3>
                    <h6>
                      {address} {cityName}, {state}
                    </h6>
                  </div>
                  <ul className="info">
                    {bedrooms > 0 && (
                      <li>
                        <i className="fa fa-bed"></i>
                        {bedrooms} beds
                      </li>
                    )}
                    {totalBaths > 0 && (
                      <li>
                        <i className="fa fa-shower"></i>
                        {totalBaths} Baths
                      </li>
                    )}
                    {sqFt !== "" && (
                      <li>
                        <i className="fa fa-crop"></i>
                        {sqFt} Sqft
                      </li>
                    )}
                    <li>
                      <i className="fa fa-home"></i>
                      {propType}
                    </li>
                  </ul>
                  {remarksConcat !== null && (
                    <>
                      <h4>Property Description</h4>
                      <p dangerouslySetInnerHTML={{ __html: remarksConcat }} />
                    </>
                  )}
                </div>
                <div className="each-block">
                  <h4>Property Details</h4>
                  <ul className="property-features-list">
                    <li>
                      <strong>Property Type:</strong>
                      <p>{propType}</p>
                    </li>
                    <li>
                      <strong>Sub Type:</strong>
                      <p>{propSubType}</p>
                    </li>
                    <li>
                      <strong>Listing Status:</strong>
                      <p>{propStatus}</p>
                    </li>
                    {sqFt !== "" && (
                      <li>
                        <strong>Square Footage:</strong>
                        <p>{sqFt} sqft</p>
                      </li>
                    )}
                    {sqFt !== "" && (
                      <li>
                        <strong>Price per Sqft:</strong>
                        <p>
                          {formatToUSAPrice(
                            parseInt(price / Number(sqFt.replace(/,/g, "")))
                          )}
                        </p>
                      </li>
                    )}
                    <li>
                      <strong>Listing ID:</strong>
                      <p>{listingID}</p>
                    </li>
                    {bedrooms > 0 && (
                      <li>
                        <strong>Bedrooms:</strong>
                        <p>{bedrooms}</p>
                      </li>
                    )}
                    {fullBaths > 0 && (
                      <li>
                        <strong>Full Baths:</strong>
                        <p>{fullBaths}</p>
                      </li>
                    )}
                    {halfBaths > 0 && (
                      <li>
                        <strong>Half Baths:</strong>
                        <p>{halfBaths}</p>
                      </li>
                    )}
                    {sqFt !== "" && (
                      <li>
                        <strong>Abv Grd Liv Area:</strong>
                        <p>{sqFt}</p>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="each-block">
                  <h4>Property Features</h4>
                  <ul className="property-features-list">
                    {advaneOptions.map((eachItem, index) => {
                      if (eachItem.key !== "Property Disclaimer") {
                        return (
                          <li key={index}>
                            <strong>{eachItem.key}:</strong>
                            <p>{eachItem.value}</p>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                {!isPopup && (
                  <>
                    <div className="each-block">
                      <h4>Building/Property Details</h4>
                      <ul className="property-features-list">
                        <li>
                          <strong>Garage - Detached</strong>
                        </li>
                        <li>
                          <strong>Stone</strong>
                        </li>
                        <li>
                          <strong>Dock Rights</strong>
                        </li>
                        <li>
                          <strong>Waterfront</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="each-block location-block">
                      <h4>Location</h4>
                      <GoogleMap
                        center={{ lat: latitude, lng: longitude }}
                        zoom={15}
                        mapContainerStyle={{ width: "100%", height: "420px" }}
                        options={{
                          streetViewControl: false,
                          fullscreenControl: false,
                        }}
                      >
                        <MarkerF position={{ lat: latitude, lng: longitude }} />
                        {directionsResponse && (
                          <DirectionsRenderer directions={directionsResponse} />
                        )}
                      </GoogleMap>
                      <div className="transportation-block">
                        <div className="destination-form">
                          <label>
                            TRAVEL TIME <strong>FROM</strong> {address},{" "}
                            {cityName} {state}
                          </label>
                          <div className="form-group">
                            <i className="fa fa-map-marker"></i>
                            <Autocomplete className="form-control-block">
                              <input
                                required
                                type="search"
                                placeholder="Choose Destination"
                                className="form-control"
                                ref={destinationRef}
                              />
                            </Autocomplete>
                          </div>
                        </div>
                        <div
                          ref={locationBlock}
                          className="transportation-type"
                        >
                          <div className="transportation-type-block">
                            <label>TYPE OF TRANSPORTATION</label>
                            <ul className="transportation-list">
                              <li>
                                <input
                                  defaultChecked
                                  type="radio"
                                  onChange={onTransportationTypeChange}
                                  name="transportationType"
                                  value="DRIVING"
                                />
                                <label>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                      stroke="none"
                                    >
                                      <path d="M1191 4484 c-132 -35 -270 -130 -338 -231 -22 -34 -96 -197 -172 -383 -73 -179 -137 -332 -142 -341 -7 -14 -24 -12 -151 23 -78 21 -161 38 -183 38 -83 -1 -172 -68 -194 -147 -14 -49 -14 -242 0 -296 23 -91 119 -181 206 -193 79 -11 84 -13 74 -32 -5 -10 -28 -70 -50 -134 -45 -131 -86 -317 -101 -459 -5 -52 -10 -399 -10 -786 0 -667 1 -694 20 -745 25 -68 90 -132 158 -158 46 -18 77 -20 272 -20 262 0 298 9 373 89 67 71 77 116 77 333 l0 178 1528 -2 1527 -3 5 -200 c3 -115 10 -211 17 -225 24 -54 72 -106 124 -135 l54 -30 233 -3 c194 -3 241 -1 282 13 65 22 132 82 161 147 l24 53 0 735 c0 662 -2 746 -18 845 -24 143 -67 308 -111 426 -38 103 -37 109 25 109 88 0 204 100 228 196 14 55 14 247 0 297 -22 79 -111 146 -194 147 -22 0 -106 -18 -185 -39 -80 -22 -146 -38 -147 -37 -2 1 -61 144 -132 318 -72 174 -142 341 -157 370 -61 121 -231 249 -375 283 -95 22 -2645 21 -2728 -1z m2619 -233 c75 -39 125 -86 157 -148 17 -32 121 -279 232 -549 213 -520 219 -540 182 -600 -42 -69 85 -65 -1841 -62 -1639 3 -1737 4 -1763 21 -40 26 -60 63 -60 111 1 31 56 176 212 557 116 283 224 532 239 554 15 22 50 56 76 76 96 72 8 68 1331 66 l1190 -2 45 -24z m-2230 -1948 c49 -34 49 -36 50 -274 0 -213 -1 -228 -20 -252 -12 -15 -38 -31 -58 -37 -50 -13 -774 -13 -824 0 -22 6 -46 23 -58 39 -19 27 -20 44 -20 251 l0 222 34 34 34 34 419 0 c378 0 421 -2 443 -17z m2846 -17 l34 -34 0 -222 c0 -207 -1 -224 -20 -251 -12 -16 -36 -33 -58 -39 -54 -15 -787 -12 -831 3 -19 7 -44 25 -55 40 -20 27 -21 41 -21 245 0 190 2 220 17 242 36 49 36 49 481 50 l419 0 34 -34z" />
                                    </g>
                                  </svg>
                                </label>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  onChange={onTransportationTypeChange}
                                  name="transportationType"
                                  value="TRANSIT"
                                />
                                <label>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                      stroke="none"
                                    >
                                      <path d="M2125 5110 c-683 -33 -1127 -152 -1408 -377 -93 -74 -169 -170 -221 -278 -52 -105 -75 -192 -87 -325 -12 -140 -11 -2605 1 -2729 48 -464 403 -819 858 -858 l74 -6 -201 -201 -201 -201 0 -68 0 -67 303 0 302 0 270 270 270 270 505 0 505 0 270 -270 270 -270 272 0 273 0 0 67 0 68 -201 201 -201 201 74 6 c382 33 717 306 822 670 46 158 48 225 43 1647 -3 1316 -4 1336 -24 1421 -44 176 -107 294 -223 410 -231 232 -592 353 -1210 404 -209 18 -894 27 -1135 15z m165 -1610 l0 -540 -672 2 -673 3 -3 538 -2 537 675 0 675 0 0 -540z m1888 3 l-3 -538 -672 -3 -673 -2 0 540 0 540 675 0 675 0 -2 -537z m-2718 -1635 c242 -72 357 -337 246 -563 -122 -246 -448 -301 -641 -108 -87 87 -132 221 -115 342 34 240 279 398 510 329z m2422 0 c27 -5 74 -27 106 -48 175 -113 234 -322 144 -514 -91 -195 -329 -280 -525 -188 -90 42 -150 100 -193 187 -101 205 -16 446 191 541 91 42 162 48 277 22z" />
                                    </g>
                                  </svg>
                                </label>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  onChange={onTransportationTypeChange}
                                  name="transportationType"
                                  value="WALKING"
                                />
                                <label>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                      stroke="none"
                                    >
                                      <path d="M2773 4785 c-81 -22 -132 -52 -193 -114 -164 -164 -168 -418 -9 -586 95 -100 196 -141 333 -133 114 7 182 36 262 110 177 166 187 423 24 601 -104 114 -268 162 -417 122z" />
                                      <path d="M2438 3821 c-78 -25 -79 -26 -463 -305 -187 -136 -345 -251 -351 -254 -5 -4 -118 -155 -249 -337 -131 -181 -242 -335 -246 -341 -9 -11 304 -246 330 -248 8 -1 112 134 232 299 193 266 226 306 291 356 40 31 74 55 76 54 2 -2 -22 -146 -53 -321 -68 -382 -69 -431 -11 -548 19 -39 54 -90 78 -112 24 -23 203 -175 398 -339 195 -163 358 -302 361 -309 4 -6 102 -274 219 -596 117 -322 214 -586 215 -588 3 -4 386 135 393 143 3 3 -100 296 -230 653 l-235 647 -199 168 c-109 93 -209 177 -221 186 l-21 17 69 390 c37 214 71 403 73 419 l6 30 113 -110 c62 -60 152 -137 200 -169 222 -148 479 -237 730 -253 l107 -6 0 211 0 211 -57 6 c-309 33 -542 144 -726 346 -78 86 -106 135 -158 281 -22 62 -54 137 -70 165 -116 203 -394 320 -601 254z" />
                                      <path d="M1947 1579 l-79 -232 -315 -374 c-173 -206 -313 -378 -311 -383 4 -11 318 -274 322 -269 2 2 156 186 343 409 l339 405 82 179 c45 99 79 183 75 186 -83 72 -371 310 -374 310 -2 0 -39 -104 -82 -231z" />
                                    </g>
                                  </svg>
                                </label>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  onChange={onTransportationTypeChange}
                                  name="transportationType"
                                  value="CYCLING"
                                />
                                <label>
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet"
                                  >
                                    <g
                                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                      stroke="none"
                                    >
                                      <path d="M2755 5106 c-83 -20 -146 -56 -211 -120 -258 -258 -116 -696 246 -758 92 -16 180 -1 276 47 168 83 268 280 236 465 -44 259 -296 427 -547 366z" />
                                      <path
                                        d="M2272 4206 c-100 -33 -103 -36 -501 -636 -244 -368 -382 -584 -394 -620 -34 -97 -3 -233 68 -301 15 -13 169 -110 343 -215 l317 -190 3 -518 c1 -379 -1 -517 -9 -514 -6 2 -22 46 -35 98 -47 189 -182 410 -323 528 l-59 49 49 89 c27 49 49 94 49 101 0 12 -227 153 -245 153 -6 0 -34 -45 -62 -99 l-53 -99 -62 19 c-323 101 -668 39 -946 -171 -187 -141 -320 -344 -383 -586 -34 -129 -34 -359 0 -488 119 -457 501 -776 961 -803 511 -29 964 313 1081 816
                                        l21 90 197 3 c191 3 197 4 253 31 61 30 105 74 140 140 l23 42 0 685 0 685 -29 53 c-17 29 -44 66 -62 82 -18 15 -139 92 -271 170 -131 79 -242 146 -247
                                        151 -11 10 335 540 350 535 6 -2 95 -65 198 -140 265 -193 232 -180 470 -186 l199 -5 42 -147 42 -148 -295 0 -295 0 42 -45 c55 -60 97 -127 123 -197 l21
                                        -58 163 0 c90 0 164 -2 164 -5 0 -2 -70 -115 -155 -251 l-155 -246 2 -278 3 -278 253 403 c140 222 260 411 266 421 11 14 20 -10 60 -151 26 -91 46 -168
                                        44 -169 -1 -2 -35 -20 -75 -40 -173 -90 -334 -260 -429 -451 -80 -162 -107 -273 -108 -455 -1 -193 25 -312 105 -474 186 -377 582 -608 999 -582 120 7
                                        189 22 312 66 419 152 701 586 675 1040 -22 376 -245 712 -583 879 -158 78 -280 105 -467 106 l-138 0 -19 65 c-31 106 -282 984 -283 993 -1 5 21 17 49
                                        28 70 27 128 79 161 147 23 47 28 70 28 132 0 125 -62 222 -174 274 -43 19 -66 21 -321 24 l-276 3 -274 200 c-151 110 -297 212 -325 226 -61 32 -159 40
                                        -223 19z m-1088 -2421 c43 -7 80 -16 83 -19 3 -2 -75 -150 -172 -328 -121 -220 -179 -336 -182 -364 -7 -57 25 -117 77 -144 37 -19 58 -20 415 -20 427 0
                                        391 10 356 -93 -40 -115 -92 -199 -181 -288 -98 -99 -176 -148 -302 -190 -419 -141 -872 125 -962 563 -106 515 348 976 868 883z m3071 -10 c222 -57 423
                                        -233 509 -445 205 -505 -193 -1062 -734 -1027 -151 10 -249 43 -372 124 -102 67 -200 178 -253 287 -63 128 -80 198 -79 336 0 140 17 210 80 339 39 78 62
                                        110 133 181 78 79 175 150 187 138 2 -3 49 -161 103 -351 55 -190 107 -360 117 -377 21 -34 86 -70 128 -70 71 0 146 75 146 146 0 16 -45 184 -100 375
                                        -55 191 -100 353 -100 360 0 16 151 6 235 -16z m-2547 -372 c31 -61 72 -168 72 -190 0 -2 -103 -3 -230 -3 -126 0 -230 3 -230 6 0 4 48 96 108 206 l107
                                        199 66 -70 c40 -42 82 -101 107 -148z"
                                      />
                                    </g>
                                  </svg>
                                </label>
                              </li>
                            </ul>
                          </div>
                          <button
                            type="button"
                            onClick={calculateRoute}
                            className="btn"
                          >
                            Calculate
                          </button>
                        </div>
                        {directionsResponse && !directionsErr && (
                          <ul className="destination-list">
                            <li>
                              <div>
                                {travelMode === "DRIVING" && (
                                  <>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512.000000 512.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                    >
                                      <g
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none"
                                      >
                                        <path d="M1191 4484 c-132 -35 -270 -130 -338 -231 -22 -34 -96 -197 -172 -383 -73 -179 -137 -332 -142 -341 -7 -14 -24 -12 -151 23 -78 21 -161 38 -183 38 -83 -1 -172 -68 -194 -147 -14 -49 -14 -242 0 -296 23 -91 119 -181 206 -193 79 -11 84 -13 74 -32 -5 -10 -28 -70 -50 -134 -45 -131 -86 -317 -101 -459 -5 -52 -10 -399 -10 -786 0 -667 1 -694 20 -745 25 -68 90 -132 158 -158 46 -18 77 -20 272 -20 262 0 298 9 373 89 67 71 77 116 77 333 l0 178 1528 -2 1527 -3 5 -200 c3 -115 10 -211 17 -225 24 -54 72 -106 124 -135 l54 -30 233 -3 c194 -3 241 -1 282 13 65 22 132 82 161 147 l24 53 0 735 c0 662 -2 746 -18 845 -24 143 -67 308 -111 426 -38 103 -37 109 25 109 88 0 204 100 228 196 14 55 14 247 0 297 -22 79 -111 146 -194 147 -22 0 -106 -18 -185 -39 -80 -22 -146 -38 -147 -37 -2 1 -61 144 -132 318 -72 174 -142 341 -157 370 -61 121 -231 249 -375 283 -95 22 -2645 21 -2728 -1z m2619 -233 c75 -39 125 -86 157 -148 17 -32 121 -279 232 -549 213 -520 219 -540 182 -600 -42 -69 85 -65 -1841 -62 -1639 3 -1737 4 -1763 21 -40 26 -60 63 -60 111 1 31 56 176 212 557 116 283 224 532 239 554 15 22 50 56 76 76 96 72 8 68 1331 66 l1190 -2 45 -24z m-2230 -1948 c49 -34 49 -36 50 -274 0 -213 -1 -228 -20 -252 -12 -15 -38 -31 -58 -37 -50 -13 -774 -13 -824 0 -22 6 -46 23 -58 39 -19 27 -20 44 -20 251 l0 222 34 34 34 34 419 0 c378 0 421 -2 443 -17z m2846 -17 l34 -34 0 -222 c0 -207 -1 -224 -20 -251 -12 -16 -36 -33 -58 -39 -54 -15 -787 -12 -831 3 -19 7 -44 25 -55 40 -20 27 -21 41 -21 245 0 190 2 220 17 242 36 49 36 49 481 50 l419 0 34 -34z" />
                                      </g>
                                    </svg>
                                    <span>By Car</span>
                                  </>
                                )}
                                {travelMode === "TRANSIT" && (
                                  <>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512.000000 512.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                    >
                                      <g
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none"
                                      >
                                        <path d="M2125 5110 c-683 -33 -1127 -152 -1408 -377 -93 -74 -169 -170 -221 -278 -52 -105 -75 -192 -87 -325 -12 -140 -11 -2605 1 -2729 48 -464 403 -819 858 -858 l74 -6 -201 -201 -201 -201 0 -68 0 -67 303 0 302 0 270 270 270 270 505 0 505 0 270 -270 270 -270 272 0 273 0 0 67 0 68 -201 201 -201 201 74 6 c382 33 717 306 822 670 46 158 48 225 43 1647 -3 1316 -4 1336 -24 1421 -44 176 -107 294 -223 410 -231 232 -592 353 -1210 404 -209 18 -894 27 -1135 15z m165 -1610 l0 -540 -672 2 -673 3 -3 538 -2 537 675 0 675 0 0 -540z m1888 3 l-3 -538 -672 -3 -673 -2 0 540 0 540 675 0 675 0 -2 -537z m-2718 -1635 c242 -72 357 -337 246 -563 -122 -246 -448 -301 -641 -108 -87 87 -132 221 -115 342 34 240 279 398 510 329z m2422 0 c27 -5 74 -27 106 -48 175 -113 234 -322 144 -514 -91 -195 -329 -280 -525 -188 -90 42 -150 100 -193 187 -101 205 -16 446 191 541 91 42 162 48 277 22z" />
                                      </g>
                                    </svg>
                                    <span>By Transit</span>
                                  </>
                                )}
                                {travelMode === "WALKING" && (
                                  <>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512.000000 512.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                    >
                                      <g
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none"
                                      >
                                        <path d="M2773 4785 c-81 -22 -132 -52 -193 -114 -164 -164 -168 -418 -9 -586 95 -100 196 -141 333 -133 114 7 182 36 262 110 177 166 187 423 24 601 -104 114 -268 162 -417 122z" />
                                        <path d="M2438 3821 c-78 -25 -79 -26 -463 -305 -187 -136 -345 -251 -351 -254 -5 -4 -118 -155 -249 -337 -131 -181 -242 -335 -246 -341 -9 -11 304 -246 330 -248 8 -1 112 134 232 299 193 266 226 306 291 356 40 31 74 55 76 54 2 -2 -22 -146 -53 -321 -68 -382 -69 -431 -11 -548 19 -39 54 -90 78 -112 24 -23 203 -175 398 -339 195 -163 358 -302 361 -309 4 -6 102 -274 219 -596 117 -322 214 -586 215 -588 3 -4 386 135 393 143 3 3 -100 296 -230 653 l-235 647 -199 168 c-109 93 -209 177 -221 186 l-21 17 69 390 c37 214 71 403 73 419 l6 30 113 -110 c62 -60 152 -137 200 -169 222 -148 479 -237 730 -253 l107 -6 0 211 0 211 -57 6 c-309 33 -542 144 -726 346 -78 86 -106 135 -158 281 -22 62 -54 137 -70 165 -116 203 -394 320 -601 254z" />
                                        <path d="M1947 1579 l-79 -232 -315 -374 c-173 -206 -313 -378 -311 -383 4 -11 318 -274 322 -269 2 2 156 186 343 409 l339 405 82 179 c45 99 79 183 75 186 -83 72 -371 310 -374 310 -2 0 -39 -104 -82 -231z" />
                                      </g>
                                    </svg>
                                    <span>By Walking</span>
                                  </>
                                )}
                                {travelMode === "CYCLING" && (
                                  <>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512.000000 512.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                    >
                                      <g
                                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                        stroke="none"
                                      >
                                        <path d="M2755 5106 c-83 -20 -146 -56 -211 -120 -258 -258 -116 -696 246 -758 92 -16 180 -1 276 47 168 83 268 280 236 465 -44 259 -296 427 -547 366z" />
                                        <path
                                          d="M2272 4206 c-100 -33 -103 -36 -501 -636 -244 -368 -382 -584 -394 -620 -34 -97 -3 -233 68 -301 15 -13 169 -110 343 -215 l317 -190 3 -518 c1 -379 -1 -517 -9 -514 -6 2 -22 46 -35 98 -47 189 -182 410 -323 528 l-59 49 49 89 c27 49 49 94 49 101 0 12 -227 153 -245 153 -6 0 -34 -45 -62 -99 l-53 -99 -62 19 c-323 101 -668 39 -946 -171 -187 -141 -320 -344 -383 -586 -34 -129 -34 -359 0 -488 119 -457 501 -776 961 -803 511 -29 964 313 1081 816
                                        l21 90 197 3 c191 3 197 4 253 31 61 30 105 74 140 140 l23 42 0 685 0 685 -29 53 c-17 29 -44 66 -62 82 -18 15 -139 92 -271 170 -131 79 -242 146 -247
                                        151 -11 10 335 540 350 535 6 -2 95 -65 198 -140 265 -193 232 -180 470 -186 l199 -5 42 -147 42 -148 -295 0 -295 0 42 -45 c55 -60 97 -127 123 -197 l21
                                        -58 163 0 c90 0 164 -2 164 -5 0 -2 -70 -115 -155 -251 l-155 -246 2 -278 3 -278 253 403 c140 222 260 411 266 421 11 14 20 -10 60 -151 26 -91 46 -168
                                        44 -169 -1 -2 -35 -20 -75 -40 -173 -90 -334 -260 -429 -451 -80 -162 -107 -273 -108 -455 -1 -193 25 -312 105 -474 186 -377 582 -608 999 -582 120 7
                                        189 22 312 66 419 152 701 586 675 1040 -22 376 -245 712 -583 879 -158 78 -280 105 -467 106 l-138 0 -19 65 c-31 106 -282 984 -283 993 -1 5 21 17 49
                                        28 70 27 128 79 161 147 23 47 28 70 28 132 0 125 -62 222 -174 274 -43 19 -66 21 -321 24 l-276 3 -274 200 c-151 110 -297 212 -325 226 -61 32 -159 40
                                        -223 19z m-1088 -2421 c43 -7 80 -16 83 -19 3 -2 -75 -150 -172 -328 -121 -220 -179 -336 -182 -364 -7 -57 25 -117 77 -144 37 -19 58 -20 415 -20 427 0
                                        391 10 356 -93 -40 -115 -92 -199 -181 -288 -98 -99 -176 -148 -302 -190 -419 -141 -872 125 -962 563 -106 515 348 976 868 883z m3071 -10 c222 -57 423
                                        -233 509 -445 205 -505 -193 -1062 -734 -1027 -151 10 -249 43 -372 124 -102 67 -200 178 -253 287 -63 128 -80 198 -79 336 0 140 17 210 80 339 39 78 62
                                        110 133 181 78 79 175 150 187 138 2 -3 49 -161 103 -351 55 -190 107 -360 117 -377 21 -34 86 -70 128 -70 71 0 146 75 146 146 0 16 -45 184 -100 375
                                        -55 191 -100 353 -100 360 0 16 151 6 235 -16z m-2547 -372 c31 -61 72 -168 72 -190 0 -2 -103 -3 -230 -3 -126 0 -230 3 -230 6 0 4 48 96 108 206 l107
                                        199 66 -70 c40 -42 82 -101 107 -148z"
                                        />
                                      </g>
                                    </svg>
                                    <span>By Cycling</span>
                                  </>
                                )}
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Distance</strong>
                                      </td>
                                      <td>:</td>
                                      <td>{distance}</td>
                                      <td>
                                        <strong>Travel time</strong>
                                      </td>
                                      <td>:</td>
                                      <td>{duration}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </li>
                          </ul>
                        )}
                        {directionsErr && (
                          <p
                            style={{
                              color: "red",
                              marginTop: "15px",
                              marginBottom: "0px",
                            }}
                          >
                            No route found between {address}, {cityName} {state}{" "}
                            and {destinationRef.current.value}.
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className="nearby-block">
                        <h4>Nearyby</h4>
                        <ul>
                          <li>
                            <h6>G</h6>
                            <p>at Myrtle-Willoughby Avs </p>
                            <strong>0.3 miles</strong>
                          </li>
                          <li>
                            <h6>G</h6>
                            <p>at Bedford-Nostrand Avs</p>
                            <strong>0.3 miles</strong>
                          </li>
                          <li>
                            <h6>G</h6>
                            <p>at Flushing Av</p>
                            <strong>0.58 miles</strong>
                          </li>
                          <li>
                            <h6 className="brown">J</h6>
                            <h6 className="red">M</h6>
                            <p>at Bedford-Nostrand Avs </p>
                            <strong>0.3 miles</strong>
                          </li>
                        </ul>
                      </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
          {!isPopup && (
            <div className="featured-areas">
              <div className="container-fluid">
                {/*<h2>
                <strong>Nearby</strong> Listings
              </h2>
               <ul className="properties-list-items">
                {nearbyLocationsArr.slice(0, 4).map((location) => (
                  <PropertyItem
                    key={location.eachProperty._id}
                    eachProperty={location?.eachProperty}
                  />
                ))}
              </ul> */}
              </div>
            </div>
          )}
        </section>

        <ContactModal title="Request a Tour" />
        <ShareModal
          media={picturesList[0]}
          address={address}
          description={remarksConcat}
        />
        <div
          className="modal fade modal-block contact-agent-modal"
          id="contact-btn"
          tabIndex="-1"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content request-block">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                    focusable="false"
                    role="img"
                    className="Icon-c11n-8-100-2__sc-13llmml-0 fBvdWz"
                  >
                    <path
                      stroke="none"
                      d="M18.83,16,29.41,5.41a2,2,0,0,0-2.82-2.82L16,13.17,5.41,2.59A2,2,0,0,0,2.59,5.41L13.17,16,2.59,26.59a2,2,0,1,0,2.82,2.82L16,18.83,26.59,29.41a2,2,0,0,0,2.82-2.82Z"
                    ></path>
                  </svg>
                </button>
                <h5>Contact Agent</h5>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label className="req-label">Name</label>
                    <input required className="form-control" type="text" />
                  </div>
                  <div className="form-group">
                    <label className="req-label">Email</label>
                    <input required className="form-control" type="text" />
                  </div>
                  <div className="form-group">
                    <label className="req-label">Phone</label>
                    <input required className="form-control" type="text" />
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea
                      defaultValue={"I am interested in " + address + "."}
                      className="form-control"
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button className="btn">Contact agent</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderLoadingView = () => {
    return (
      <div className="loader">
        <div className="loading-two"></div>
      </div>
    );
  };

  return (
    <div style={{ minHeight: "100vh", position: "relative" }}>
      {propertiesData !== null ? renderPropertyDetails() : renderLoadingView()}
    </div>
  );
}
export default PropertyDetails;
